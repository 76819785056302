
.statusBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.statusIcon {
  font-size: 1.8rem;
  padding: .5rem;
}

.statusHeading {
  padding: 0;
  margin: 0;
}

.form {
  padding:1rem;
}

@primary-color: #1f4662;