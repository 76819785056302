.Card {
  max-width: 600px;
  margin: .5rem;
  flex-grow: 1;
}

.cardsContainer{
  display: flex;
  flex-flow: row wrap;
}

@primary-color: #1f4662;