

.Card {
  min-width: 300px;
  margin: .5rem;
  flex-grow: 1;
}


.cardsContainer{
  display: flex;
  flex-flow: row wrap;

}


.tagBar {
  margin: 0 0 1rem 0;
}

.linkButton {
  font-size: 1.8rem;
  padding: .2rem;
  border: .2rem solid transparent;
  border-radius: 50%;
}

.linkButton:hover {
  border: .2rem dashed #1f4662;
  border-radius: 50%;
}

@primary-color: #1f4662;